import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transaction } from './necs-transactions.types';
import { baseTypedDataReducer, BaseTypedDataReducerInitialState } from '../base-typed-reducer';

export interface TransactionsInitialState extends BaseTypedDataReducerInitialState<Transaction> {
  perPage: number;
  revenue: string;
  expense: string;
  selectedTransaction: Transaction | null;
  transactionTypes: Array<{ id: string; name: string }>;
  transactionStatuses: Array<{ id: string; name: string }>;
}

const { initialState, AC, reducer, thunks } = baseTypedDataReducer<Transaction, TransactionsInitialState>(
  'necs-transactions',
);

export const transactionsInitialState: TransactionsInitialState = {
  ...initialState,
  perPage: 25,
  revenue: '',
  expense: '',
  selectedTransaction: null,
  transactionTypes: [
    { id: 'buy', name: 'Buy' },
    { id: 'sell', name: 'Sell' },
  ],
  transactionStatuses: [
    { id: 'pending', name: 'Pending' },
    { id: 'completed', name: 'Completed' },
    { id: 'cancelled', name: 'Cancelled' },
  ],
};

const necsTransactions = createSlice({
  name: '/nibchain/necs-transactions',
  initialState: transactionsInitialState,
  reducers: {
    setRevenue: (state, action: PayloadAction<string>) => {
      state.revenue = action.payload;
    },
    setExpense: (state, action: PayloadAction<string>) => {
      state.expense = action.payload;
    },
    setSelectedTransaction: (state, action: PayloadAction<Transaction | null>) => {
      state.selectedTransaction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      return reducer(state as TransactionsInitialState, action);
    });
  },
});

export const NecsTransactionsAC = AC;
export const onNecsTransactionsSort = thunks.onSort;
export const onNecsTransactionsFilter = thunks.onFilter;
export const setNecsTransactionOffset = AC.setOffset;

export const {
  setRevenue: setNecsTransactionRevenue,
  setExpense: setNecsTransactionExpense,
  setSelectedTransaction: setSelectedNecsTransaction,
} = necsTransactions.actions;

export default necsTransactions.reducer;
