import hexRgb from 'hex-rgb';

const TOGGLE_INIT = '/nibchain/app/TOGGLE_INIT';
const TOGGLE_HELP = '/nibchain/app/TOGGLE_HELP';
const TOGGLE_LOADING = '/nibchain/app/TOGGLE_LOADING';
const SET_PLATFORM_SETTINGS = '/nibchain/app/SET_PLATFORM_SETTINGS';

const initialState = {
  isInited: false,
  isHelpActive: false,
  loading: false,
  platformSettings: {
    companyName: 'Loading...',
    accentColor: {
      hex: '#D176FB',
      r: 209,
      g: 118,
      b: 251,
    },
    logoUrl: null,
    faviconUrl: null,
    adminPanelNameAlias: '',
    marketplaceNameAlias: '',
  },
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INIT:
      return {
        ...state,
        isInited: action.payload,
      };
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TOGGLE_HELP:
      return {
        ...state,
        isHelpActive: !state.isHelpActive,
      };
    case SET_PLATFORM_SETTINGS:
      return {
        ...state,
        platformSettings: action.payload,
      };
    default:
      return state;
  }
};

const toggleInitAC = (status) => ({ type: TOGGLE_INIT, payload: status });
const toggleLoadingAC = (status) => ({ type: TOGGLE_LOADING, payload: status });
const toggleHelpAC = () => ({ type: TOGGLE_HELP });

export const toggleHelp = () => (dispatch) => {
  dispatch(toggleHelpAC());
};

export const setSettings = (config) => (dispatch) => {
  dispatch(toggleLoadingAC(true));
  const settings = {
    companyName: config.company_name,
    accentColor: {
      hex: config.accent_color,
      r: hexRgb(config.accent_color).red,
      g: hexRgb(config.accent_color).green,
      b: hexRgb(config.accent_color).blue,
    },
    logoUrl: config.logoUrl,
    faviconUrl: config.faviconUrl,
    qrCodeLogo: config.qrCodeLogoUrl,
    adminPanelNameAlias: config.admin_panel_name_alias,
    marketplaceNameAlias: config.marketplace_name_alias,
  };

  dispatch({ type: SET_PLATFORM_SETTINGS, payload: settings });
  dispatch(toggleLoadingAC(false));
};

export default appReducer;
