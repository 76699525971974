import { toast } from 'react-toastify';
import { Base64 } from 'js-base64';
import _ from 'lodash';

export const validator = async (schema, data) => {
  try {
    let validate = await schema.validate(data, { abortEarly: false });
    return { error: false, cells: [] };
  } catch (error) {
    let cells = [];
    for (const err of error.inner) {
      toast.error(err.message);
      cells.push(err.path);
    }
    return { error: true, cells };
  }
};

export const getBackendUrl = (uri) => {
  const API_URL = process.env.REACT_APP_API_URL;
  return `${API_URL}${uri}`;
};

export const safeEncodeBase64 = (html) => {
  try {
    let localHtml = Base64.encode(html);
    return localHtml;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const safeDecodeBase64 = (base64) => (Base64.isValid(base64) ? Base64.decode(base64) : false);

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const exploreFile = (value) => {
  let win = window.open(value instanceof Blob ? URL.createObjectURL(value) : value, '_blank');
  if (value instanceof Blob) {
    if ((value.type || value.ext).includes('image')) {
      win.document.write(`<img src=${URL.createObjectURL(value)} />`);
    }
  }
};

export const htmlToBBCode = (html) => {
  html = html.replace(/<pre(.*?)>(.*?)<\/pre>/gim, '[code]$2[/code]');

  html = html.replace(/<h[1-7](.*?)>(.*?)<\/h[1-7]>/, '\n[h]$2[/h]\n');

  //paragraph handling:
  //- if a paragraph opens on the same line as another one closes, insert an extra blank line
  //- opening tag becomes two line breaks
  //- closing tags are just removed
  // html += html.replace(/<\/p><p/<\/p>\n<p/gi;
  // html += html.replace(/<p[^>]*>/\n\n/gi;
  // html += html.replace(/<\/p>//gi;

  html = html.replace(/<br(.*?)>/gi, '\n');
  html = html.replace(/<textarea(.*?)>(.*?)<\/textarea>/gim, '[code]$2[/code]');
  html = html.replace(/<b>/gi, '[b]');
  html = html.replace(/<i>/gi, '[i]');
  html = html.replace(/<u>/gi, '[u]');
  html = html.replace(/<\/b>/gi, '[/b]');
  html = html.replace(/<\/i>/gi, '[/i]');
  html = html.replace(/<\/u>/gi, '[/u]');
  html = html.replace(/<em>/gi, '[b]');
  html = html.replace(/<\/em>/gi, '[/b]');
  html = html.replace(/<strong>/gi, '[b]');
  html = html.replace(/<\/strong>/gi, '[/b]');
  html = html.replace(/<cite>/gi, '[i]');
  html = html.replace(/<\/cite>/gi, '[/i]');
  html = html.replace(/<font color="(.*?)">(.*?)<\/font>/gim, '[color=$1]$2[/color]');
  html = html.replace(/<font color=(.*?)>(.*?)<\/font>/gim, '[color=$1]$2[/color]');
  html = html.replace(/<link(.*?)>/gi, '');
  html = html.replace(/<li(.*?)>(.*?)<\/li>/gi, '[*]$2');
  html = html.replace(/<ul(.*?)>/gi, '[list]');
  html = html.replace(/<\/ul>/gi, '[/list]');
  html = html.replace(/<div>/gi, '\n');
  html = html.replace(/<\/div>/gi, '\n');
  html = html.replace(/<td(.*?)>/gi, ' ');
  html = html.replace(/<tr(.*?)>/gi, '\n');

  html = html.replace(/<img(.*?)src="(.*?)"(.*?)>/gi, '[img]$2[/img]');
  html = html.replace(/<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/gi, '[url=$2]$4[/url]');

  html = html.replace(/<head>(.*?)<\/head>/gim, '');
  html = html.replace(/<object>(.*?)<\/object>/gim, '');
  html = html.replace(/<script(.*?)>(.*?)<\/script>/gim, '');
  html = html.replace(/<style(.*?)>(.*?)<\/style>/gim, '');
  html = html.replace(/<title>(.*?)<\/title>/gim, '');
  html = html.replace(/<!--(.*?)-->/gim, '\n');

  html = html.replace(/\/\//gi, '/');
  html = html.replace(/http:\//gi, 'http://');

  html = html.replace(/<(?:[^>'"]*|(['"]).*?\1)*>/gim, '');
  html = html.replace(/\r\r/gi, '');
  html = html.replace(/\[img]\//gi, '[img]');
  html = html.replace(/\[url=\//gi, '[url=');

  html = html.replace(/(\S)\n/gi, '$1 ');

  return html;
};

export const b64toBlob = async (base64, type = 'application/octet-stream') => {
  try {
    let res = await fetch(base64).then((res) => res.blob());
    return res;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const LOCALE = process.env.REACT_APP_LOCALE || 'ru-RU';
const DATETIME_OPTS = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };

export const formatDateTime = (date) =>
  new Intl.DateTimeFormat(LOCALE, DATETIME_OPTS).format(new Date(date)).replace(',', '');

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateFileName = (file, def_ext = '.png') => {
  let localFile = file;
  let fileName = localFile.name.replace(/ |-/g, '_');
  fileName = /[.]/.exec(fileName) ? fileName : fileName + def_ext;
  localFile = new File([localFile], fileName);
  return localFile;
};

export function getDateWithoutNanoseconds(date) {
  // 24-02-2021 14:51:21.0000000 -> 24-02-2021, 14:51:21
  return date.split('.')[0].split(' ').join(', ');
}

export const sortByOrder = (order, items) => {
  const payload = [];
  if (order?.length && items.length)
    for (const item of order) {
      const asset = _.find(items, ['id', item]);
      if (!asset) continue;
      payload.push(asset);
    }
  return payload;
};

export function dataURLtoFile(dataurl, filename = 'file_' + new Date().getTime()) {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export { _ };

export const strToObj = (str, out_delimeter = ',', in_delimeter = '=') => {
  let payload = {};
  let splited = str.split(out_delimeter);
  for (const entry of splited) {
    const [key, value] = entry.split(in_delimeter);
    payload[key] = value;
  }
  return payload;
};

export function openInNewTab(url) {
  window.open(url, '_blank');
}

export const getHashCode = (s) => {
  return String(s)
    .split('')
    .reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
};

export const getUserFullName = (user) =>
  user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.login;

export const analyzeImage = (imgElement) => {
  if (!imgElement) return null;
  if (!imgElement.complete) return null;
  if (!imgElement.width) return null;
  if (!imgElement.height) return null;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = imgElement.width;
  canvas.height = imgElement.height;

  try {
    ctx.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const { data } = imageData;

    let r = 0,
      g = 0,
      b = 0;
    for (let i = 0; i < data.length; i += 4) {
      r += data[i];
      g += data[i + 1];
      b += data[i + 2];
    }

    const pixelCount = data.length / 4;
    r = Math.floor(r / pixelCount);
    g = Math.floor(g / pixelCount);
    b = Math.floor(b / pixelCount);

    return r * 0.299 + g * 0.587 + b * 0.114;
  } catch (error) {
    console.error('Cross-origin issue with image data:', error);
    return null;
  }
};

export const formatCurrency = (value) => {
  const numericValue = Number(value);
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericValue);
};
